import axios from "axios";

export default class LoginService {

    private serverUrl: string = '';
    constructor(serverRoot: string) {
        this.serverUrl = serverRoot;
    }

    async loginUser(id: string, password: string) {
        return axios.post(this.serverUrl + "api/login", {handle: id, password: password}).then(res => {
            return res.data;
        });
    }

    async verifyToken(token: string) {
        return axios.post(this.serverUrl + "api/verify", {token}).then(res => {
            return res.data;
        });
    }
}
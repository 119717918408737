import { Services } from "../services/Services";
import { AddCategory } from "./AddCategory";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { SignIn } from "./SignIn";
import { AdminHome } from "./AdminHome";
import { LoggedInUserProvider } from "../hooks/UseLogin";

interface HomeProps {
    services: Services;
}
export const Home: React.FC<HomeProps> = ({ services }) => {
    return (
        <BrowserRouter>
            <LoggedInUserProvider services={services}>
                <Routes>
                    <Route path="/" element={<SignIn services={services} />} />
                    <Route path="/admin/home" element={<AdminHome services={services} />} />
                    <Route path="/admin/category/add" element={<AddCategory services={services} />} />
                </Routes>
            </LoggedInUserProvider>
        </BrowserRouter>
    );
};


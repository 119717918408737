import { StoreConfig } from "../config/ConfigLoader";
import { CategoryService } from "./CategoryService";
import { ImageService } from "./ImageService";
import LoginService from "./LoginService";
import { ProductService } from "./ProductService";

export interface Services {
    categoryService: CategoryService;
    productService: ProductService;
    imageService: ImageService;
    loginService: LoginService;
    setBearerToken(token: string): void;
}

export class JwtServices implements Services {
    categoryService:CategoryService;
    productService:ProductService;
    imageService:ImageService;
    loginService: LoginService;

    constructor(config: StoreConfig) {
        this.categoryService = new CategoryService(config.storecore_api_service ?? '');
        this.productService = new ProductService(config.storecore_api_service ?? '');
        this.imageService = new ImageService(config.image_api_service ?? '');
        this.loginService = new LoginService(config.login_api_service ?? '');
    }
   
    
    setBearerToken(token: string) {
        this.categoryService.setBearerToken(token);
        this.productService.setBearerToken(token);
        this.imageService.setBearerToken(token);
    }
}
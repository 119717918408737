
import { Button, InputGroup, ButtonGroup, Form, Container, Row, Col, Table } from "react-bootstrap";
import { useEffect, useState } from "react";

import { Category, CategoryRefFlat, PriceBand } from "../models/Product";
import { Services } from "../services/Services";

interface PriceBandCreateProps {
    services: Services;
    category: CategoryRefFlat
}

export const PriceBandCreate: React.FC<PriceBandCreateProps> = ({ services, category }) => {
    const [basePrice, setBasePrice] = useState(0.0);
    const [priceBands, setPriceBands] = useState([] as PriceBand[]);
    useEffect(() => {
        if (category) {
            services.categoryService.getPriceBands(category).then(results => {
                setPriceBands(results.pricebands);
            })
        }
    }, [category])
    const addPriceBand = () => {
        services.categoryService.addPriceBand(category, basePrice).then(result => {
            services.categoryService.getPriceBands(category).then(results => {
                setPriceBands(results.pricebands);
            });
        });
    }

    const getPriceBands = () => {
        return priceBands.map(priceband => <tr>{priceband.base_price}</tr>)
    }

    return (
        <Container>
            <Row>
                <Col>
                    <InputGroup>
                        <InputGroup.Text>Rs.</InputGroup.Text>
                        <Form.Control onChange={(e) => setBasePrice(parseFloat(e.target.value))} />
                        <Button onClick={addPriceBand}>Add Base Price</Button>
                    </InputGroup>
                    <Table>
                        <tbody>
                            {getPriceBands()}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};
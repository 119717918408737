import { PropsWithChildren, useEffect } from "react";
import { styles } from "../styles/default";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { Image, Container, Row, Col, Button } from "react-bootstrap";
import { useLogin } from "../hooks/UseLogin";
import { Services } from "../services/Services";

interface TitleBarProps extends PropsWithChildren {
}
export const TitleBar: React.FC<TitleBarProps> = ({ children }) => {
    const navigate = useNavigate();
    const {user, logout} = useLogin();

    useEffect(() => {
        if(user == ''){
            navigate("/");
        }
    }, [user]);

    const signOut = () => {
        if(logout){
            logout();
        }
    }
    return (
        <div style={{ backgroundColor: styles.highlightColor, color: "#FFFFF0" }}>
            <Row>
                <Col xs={2}>
                </Col>
                <Col xs={2} >
                    <Image style={{ margin: 10 }} src="/images/logo.png" height={70} width={70}></Image>
                </Col>
                <Col xs={6}>
                    <Row>
                        <Col sm={6}>
                            <h2 style={{ padding: 20 }}>Palaiyan of Supplies</h2>
                        </Col>
                        <Col sm={6}>
                            {user != '' ?
                                <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                                    {children}
                                </div> :
                                <div></div>
                            }
                        </Col>
                    </Row>
                </Col>
                <Col xs={2}>
                    {user != '' ?
                        <div style={{ padding: 20 }}>
                            <Button onClick={signOut}>Sign Out</Button>
                        </div> :
                        <div></div>
                    }
                </Col>
            </Row>
        </div>
    );
};


import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form"
import { Container, Row, Col, Card, ButtonGroup, Button, InputGroup } from "react-bootstrap";
import { Services } from "../services/Services";
import { MaterialRef, ProductForm, Measure, Category, ProductFormRef, MeasureRef, CategoryRefFlat } from "../models/Product";
import { DimensionSelect } from "./DimensionSelect";
import { useLogin } from "../hooks/UseLogin";
import { CategoryList } from "./CategoryList";
import { PriceBandCreate } from "./PriceBandCreate";



interface CategoryEditProps {
    services: Services;
    onCreate?: (category: Category) => void
}

export const CategoryCreate: React.FC<CategoryEditProps> = ({ services, onCreate }) => {
    const [materials, setMaterials] = useState([] as string[]);
    const [productForms, setProductForms] = useState([] as string[]);
    const [measures, setMeasures] = useState([] as string[]);
    const [categories, setCategories] = useState([] as CategoryRefFlat[]);

    const [material, setMaterial] = useState('');
    const [productForm, setProductForm] = useState('');
    const [measure, setMeasure] = useState('');
    const loggedInUser = useLogin();

    useEffect(() => {
        refresh();
    }, [loggedInUser?.user])

    const refresh = () => {
        services.categoryService.getMaterialsRaw().then(result => {
            if (result.materials) {
                setMaterials(result.materials.map((material: MaterialRef) => material.name));
            }
        })
        services.categoryService.getFormsRaw().then(result => {
            if (result.forms) {
                setProductForms(result.forms.map((form: ProductFormRef) => form.name));
            }
        })
        services.categoryService.getMeasuresRaw().then(result => {
            if (result.measures) {
                setMeasures(result.measures.map((measure: MeasureRef) => measure.name));
            }
        })
        services.categoryService.getAllCategories().then(result => {
            if (result.categories) {
                setCategories(result.categories);
            }
        })
    }
    const addMaterial = (rawMaterial: string) => {
        services.categoryService.createMaterial(rawMaterial).then(mat => {
            services.categoryService.getMaterialsRaw().then(result => {
                if (result.materials) {
                    setMaterials(result.materials.map((material: MaterialRef) => material.name));
                }
            })
        })
    }
    const addProductForm = (productForm: string) => {
        services.categoryService.createForm(productForm).then(form => {
            services.categoryService.getFormsRaw().then(result => {
                setProductForms(result.forms.map((form: ProductFormRef) => form.name));
            })
        })
    }

    const addMeasure = (measure: string) => {
        services.categoryService.createMeasure(measure).then(measure => {
            services.categoryService.getMeasuresRaw().then(result => {
                setMeasures(result.measures.map((measure: MeasureRef) => measure.name));
            })
        })
    }

    const createCategory = () => {
        services.categoryService.createCategoryRef(material, productForm, measure).then(category => {
            if (onCreate) {
                onCreate(category);
            }
            services.categoryService.getAllCategories().then(result => {
                if (result.categories) {
                    setCategories(result.categories);
                }
            })
        });
    }

    return (
        <Container style={{maxWidth: "inherit"}}>
            <Row>
                <Col sm={2}></Col>
                <Col sm={3}>
                    <Card style={{marginTop: 10}}>
                        <Card.Header style={{backgroundColor: "#ff60f0"}}>Select Dimensions</Card.Header>
                        <Card.Body style={{backgroundColor: "#FF9AF0"}}>
                            <ButtonGroup>
                                <div style={{marginRight: 2}}>
                                <DimensionSelect dimension="Material" values={materials} addNewDimension={addMaterial} selectDimension={setMaterial} />
                                </div>
                                <div  style={{marginRight: 2}}>
                                <DimensionSelect dimension="Form" values={productForms} addNewDimension={addProductForm} selectDimension={setProductForm} />
                                </div>
                                <div>
                                <DimensionSelect dimension="Measure" values={measures} addNewDimension={addMeasure} selectDimension={setMeasure} />
                                </div>
                            </ButtonGroup>
                            <Card style={{marginTop: 5}}>
                                <Card.Body style={{textAlign: "left"}}>
                                    <label>Material: <span style={{fontWeight: "bold"}}>{material}</span></label><br />
                                    <label>Form: <span style={{fontWeight: "bold"}}>{productForm}</span></label><br />
                                    <label>Measure: <span style={{fontWeight: "bold"}}>{measure}</span></label><br />
                                    <Button onClick={createCategory}>Create Category</Button>
                                </Card.Body>
                            </Card>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={5}>
                    <CategoryList services={services} categories={categories} renderCategory={(category) => <PriceBandCreate services={services} category={category} />}></CategoryList>
                </Col>
                <Col sm={2}></Col>
            </Row>

        </Container>
    );
};


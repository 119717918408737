import { Button } from "react-bootstrap";
import { useNavigate } from 'react-router-dom'

interface ActionsBarProps {
}
export const ActionsBar: React.FC<ActionsBarProps> = () => {
    const navigate = useNavigate();

    const toAddCategory = () => {
        navigate("/admin/category/add");
    }

    return (
        <div>
                <Button style={{marginRight: 2}} onClick={toAddCategory}>Add Category</Button>
        </div>
    );
};

import { HeaderBar } from "../palaiyan/HeaderBar";
import { Services } from "../services/Services";
import { CategoryCreate } from "../components/CategoryCreate";
import { styles } from "../styles/default";

interface AddCategoryProps {
    services: Services;
}
export const AddCategory: React.FC<AddCategoryProps> = ({services}) => {
    return (
        <div style={{backgroundColor: styles.bodyColor}}>
            <HeaderBar/>
            <CategoryCreate services={services}/>
        </div>
    );
};


import { Material, ProductForm, Measure, CategoryRef, Category, MaterialRef, CategoryRefFlat } from "../models/Product";
import axios from "axios";
import { Product } from "../models/Product";
import { ServiceCommon } from "./ServiceCommon";
import { v4 as uuidv4 } from 'uuid';

export class ProductService {
    private bearerToken: string = '';
    private serverUrl: string = '';
    constructor(serverRoot: string) {
        this.serverUrl = serverRoot + "api/storecore/";
    }

    public setBearerToken(token: string) {
        this.bearerToken = token;
    }

    private async get(path: string) {
        let config = {
            headers: { 'Authorization': "bearer " + this.bearerToken }
        };
        return axios.get(this.serverUrl + path, config).then(res => {
            return res.data;
        })
    }

    private async post(path: string, data: any) {
        let config = {
            headers: { 'Authorization': "bearer " + this.bearerToken }
        };
        return axios.post(this.serverUrl + path, data, config).then(res => {
            return res.data;
        })
    }

    private async delete(path: string) {
        let config = {
            headers: { 'Authorization': "bearer " + this.bearerToken }
        };
        return axios.delete(this.serverUrl + path, config).then(res => {
            return res.data;
        })
    }

    async createProduct(product: Product) {
        return this.post("product", { price_band_id: product.priceBandId, details: product.details }).then(product => {
            return product;
        })
    }

    async deleteProduct(productId: string) {
        return this.delete("product/" + productId).then(product => {
            return product;
        })
    }

    async getAllProducts(categoryRef: CategoryRefFlat) {
        return this.get("category/" + ServiceCommon.refToUri(categoryRef) + "/product?salt=" + uuidv4()).then(products => {
            return products;
        })
    }

}
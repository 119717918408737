import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form"
import { Container, Row, Col, Button, InputGroup, Dropdown, ListGroup, ListGroupItem } from "react-bootstrap";
import { Services } from "../services/Services";
import { MaterialRef, ProductForm, Measure, Category } from "../models/Product";
import { CategoryDetailsView } from "./CategoryDetailsView";


interface DimensionsSelectProps {
    dimension: string;
    values: string[];
    addNewDimension: (dimension: string) => void;
    selectDimension: (dimension: string) => void;
}

export const DimensionSelect: React.FC<DimensionsSelectProps> = ({dimension, values, addNewDimension, selectDimension }) => {
    const [dimensionValue, setDimensionValue] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);

    const selectAndClose = (value: string) => {
        setShowDropdown(false);
        selectDimension(value);
    }

    const newDimension = () => {
        console.log(dimensionValue);
        addNewDimension(dimensionValue);
    }

    return (
        <Dropdown show={showDropdown} onClick={() => setShowDropdown(!showDropdown)}>
            <Dropdown.Toggle>{dimension}</Dropdown.Toggle>
            <Dropdown.Menu>
                <Dropdown.Item style={{ width: 400,backgroundColor: "#FF10F0" }} onClick={(event) => event.stopPropagation()}>
                    <InputGroup>
                        <Form.Control onChange={event => setDimensionValue(event.target.value)}></Form.Control>
                        <Button onClick={newDimension}>Add New {dimension}</Button>
                    </InputGroup>
                    <div style={{ height: 300 }}>
                        <ListGroup>
                            {values.length == 0 ? <ListGroup.Item>No {dimension} found</ListGroup.Item> :
                                values.map(value => {
                                    return <ListGroup.Item onClick={() =>selectAndClose(value)}>{value}</ListGroup.Item>
                                })}
                        </ListGroup>
                    </div>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};




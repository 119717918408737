import { PropsWithChildren } from "react";
import { TitleBar } from "../components/TitleBar";
import { ActionsBar } from "./ActionsBar";
import { Services } from "../services/Services";

interface HeaderBarProps extends PropsWithChildren {
} 

export const HeaderBar: React.FC<HeaderBarProps> = ({children}) => {
return <TitleBar>
    <ActionsBar/>
</TitleBar>
};

  
import { HeaderBar } from "../palaiyan/HeaderBar";
import { Services } from "../services/Services";
import { styles } from "../styles/default";

interface AdminHomeProps {
    services: Services;
}
export const AdminHome: React.FC<AdminHomeProps> = ({ services }) => {
    return (
        <div style={{ backgroundColor: styles.bodyColor, flex: 1, height: "100vh" }}>
            <HeaderBar/>
        </div>
    );
};


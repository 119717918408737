import React, { useEffect, useState, useContext, ReactNode } from 'react';
import { ConfigLoader, StoreConfig } from '../config/ConfigLoader';


const ConfigContext = React.createContext<StoreConfig>({});

export const ConfigProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [config, setConfig] = useState({});

    useEffect(() => {
        setConfig(ConfigLoader.load());
    }, [])

    return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>

}

export const useConfig = () => {
    return useContext(ConfigContext);
};